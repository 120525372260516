// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-body {
    /* background: linear-gradient(to bottom, #14041b, #370a31);   */
    background: linear-gradient(to bottom, #08011A, #35052C, #08011A);   
}`, "",{"version":3,"sources":["webpack://./src/Components/HomeComponents/HomeBody/HomeBody.css"],"names":[],"mappings":"AAAA;IACI,gEAAgE;IAChE,iEAAiE;AACrE","sourcesContent":[".home-body {\n    /* background: linear-gradient(to bottom, #14041b, #370a31);   */\n    background: linear-gradient(to bottom, #08011A, #35052C, #08011A);   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
