import React, { createContext, useState } from 'react';

export const TokensContext = createContext();

export const TokensProvider = ({ children }) => {
    const [tokens, setTokens] = useState(10);

    return (
        <TokensContext.Provider value={{ tokens, setTokens }}>
            {children}
        </TokensContext.Provider>
    );
};
