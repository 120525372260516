import './Chatbot.css';
import React from 'react';
import NavBar from '../../Components/NavBar/NavBar';
import Tokens from '../../Components/ChatbotComponents/Tokens/Tokens';
import Chat from '../../Components/ChatbotComponents/Chat/Chat';
import QuestionInput from '../../Components/ChatbotComponents/QuestionInput/QuestionInput';

function Chatbot() {
    return (
        <>
        <div className="chatbot-page">
            <NavBar />
            <Tokens/>
            <Chat />
            <QuestionInput />
        </div>
        </>
    );
}

export default Chatbot;