import React, { useEffect, useRef, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../Context/AuthContext';

const GoogleAuth = () => {
  
    const location = useLocation();
    const navigate = useNavigate();
    const { userLogin } = useContext(AuthContext);
    const processed = useRef(false);
    const socialError = location.state?.socialError;

        const handleGoogleCallback = async (code) => {
          try {
              const response = await axios.post(`${process.env.REACT_APP_CHATAGENT_URI}/auth/google`, { code });
              console.log('Backend response:', response.data);
          
              userLogin(response.data['survey_data']);
              navigate('/chatbot');

          } catch (error) {
              console.error('Error sending code to backend:', error);
              navigate('/login', { state: { socialError: "Error logging in with Google. Please try again or login with email." } });
          }
        };
      


      useEffect(() => {

        if (processed.current) return; // Process code for access token once to avoid errors
        processed.current = true;

        // Extract the code parameter from the URL
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');

        // Handle authentication logic with the code
        if (code) {
          handleGoogleCallback(code);
          
        } else {
          console.error('No code found in URL');
        }


    }, [location.search]);

  return (
    <div>
      <p>Processing...</p>
    </div>
  );
};

export default GoogleAuth;