import React from 'react';
import './Disclaimer.css';

const Disclaimer = () => {
    return (
        <div className ="disclaimer-container">
            <div className="disclaimer-banner">
                <p className="disclaimer-text"><strong>Disclaimer:</strong> This chatbot provides support for neurodiverse individuals but is not intended to be used as a diagnostic tool.</p>
            </div>
        </div>

    );
}

export default Disclaimer;