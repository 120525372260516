import React, { createContext, useState, useEffect, useContext } from 'react';
import { ChatContext } from './ChatContext';
import axios from "axios";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { resetSurveyData } = useContext(ChatContext);

    const userLogin = (surveyData) => {
        setIsLoggedIn(true);
        

        if (surveyData) {
            localStorage.setItem('surveyData', JSON.stringify(surveyData));
        }
        
        // localStorage.setItem('accessToken', accessToken);
        // localStorage.setItem('refreshToken', refreshToken);
        console.log("Logged in?", isLoggedIn);
    };

    const userLogout = async () => {

        try {
            const response = await axios.post(`${process.env.REACT_APP_CHATAGENT_URI}/auth/logout`);
            console.log('Response from backend:', response);

            if (response.status === 200) {
                // User successfully logged out

                localStorage.removeItem('surveyData');
                resetSurveyData();
                setIsLoggedIn(false);
                

                console.log("Log out successful");
                console.log("Logged in?", isLoggedIn);
            } else {
                console.error('Log out failed');
            }
        } catch (error) {
            console.error('Error logging out:', error);
        }

    };

    useEffect(() => {
        const handlePageUnload = () => {
            localStorage.removeItem('surveyData');
            resetSurveyData();
            setIsLoggedIn(false);
        };

        // Add event listener for beforeunload event
        window.addEventListener('beforeunload', handlePageUnload);

        // Clean up event listener when component unmounts
        return () => {
            window.removeEventListener('beforeunload', handlePageUnload);
        };
    }, [resetSurveyData]);

    return (
         <AuthContext.Provider value={{ isLoggedIn, isLoading, userLogin, userLogout }}>
            {children}
         </AuthContext.Provider>
    );
};
