import './HomeBody.css';
import Features from '../Features/Features';
import Footer from '../Footer/Footer';
import Disclaimer from '../Disclaimer/Disclaimer';

function HomeBody() {
    return (
        <div className ="home-body">
            <Features />
            <Disclaimer />
            <Footer />
        </div>
    );
}

export default HomeBody;