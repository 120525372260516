// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Orbitron);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-bar {
    display: flex;
    justify-content: space-between;
    background-color: #000000;
    align-items: center;
    width: 100%;
    top: 1;
    position:relative;
    padding: 18px;
}


.logo-container {
    
}

.logo {
    font-family: Orbitron;
    font-weight: 600;
    font-size: 22px;
    color: #dfdfdf; 
    margin-left: 20px;
    text-decoration: none;

} 

.nav-buttons{
    margin-right: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
 }

.button {
    text-decoration: none;
 } 

.log-in {
    background-color:#000000;
    color:#fff;
    text-decoration: none;
    margin-right: 20px;
    font-weight: 500;
    font-size: 17px;
    cursor: pointer;
    }
    
.sign-up {
    background: linear-gradient(to left, #9474C2,  #594283); 
    color: #fff;
    border-radius: 3px;
    padding: 8px 20px; 
    text-decoration: none;
    font-weight: 500;
    font-size: 17px;
    cursor: pointer;
    }

.user-photo {
    width: 38px;
    height: 38px;
}`, "",{"version":3,"sources":["webpack://./src/Components/NavBar/NavBar.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;IACX,MAAM;IACN,iBAAiB;IACjB,aAAa;AACjB;;;AAGA;;AAEA;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,iBAAiB;IACjB,qBAAqB;;AAEzB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,mBAAmB;CACtB;;AAED;IACI,qBAAqB;CACxB;;AAED;IACI,wBAAwB;IACxB,UAAU;IACV,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;IACf,eAAe;IACf;;AAEJ;IACI,uDAAuD;IACvD,WAAW;IACX,kBAAkB;IAClB,iBAAiB;IACjB,qBAAqB;IACrB,gBAAgB;IAChB,eAAe;IACf,eAAe;IACf;;AAEJ;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Orbitron');\n\n.nav-bar {\n    display: flex;\n    justify-content: space-between;\n    background-color: #000000;\n    align-items: center;\n    width: 100%;\n    top: 1;\n    position:relative;\n    padding: 18px;\n}\n\n\n.logo-container {\n    \n}\n\n.logo {\n    font-family: Orbitron;\n    font-weight: 600;\n    font-size: 22px;\n    color: #dfdfdf; \n    margin-left: 20px;\n    text-decoration: none;\n\n} \n\n.nav-buttons{\n    margin-right: 55px;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n }\n\n.button {\n    text-decoration: none;\n } \n\n.log-in {\n    background-color:#000000;\n    color:#fff;\n    text-decoration: none;\n    margin-right: 20px;\n    font-weight: 500;\n    font-size: 17px;\n    cursor: pointer;\n    }\n    \n.sign-up {\n    background: linear-gradient(to left, #9474C2,  #594283); \n    color: #fff;\n    border-radius: 3px;\n    padding: 8px 20px; \n    text-decoration: none;\n    font-weight: 500;\n    font-size: 17px;\n    cursor: pointer;\n    }\n\n.user-photo {\n    width: 38px;\n    height: 38px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
