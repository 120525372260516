// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notice {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 15px 35px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    align-items: start;    
    width: 80%; 
    max-width: 500px; 
    min-width: 200px; 
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
    z-index: 1;
}

.close-button {
    position: relative;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 20px;
    align-self: end;
}

.notice-title {
    color:#000000;
    display: flex;
    align-items: center;
    font-size: 22px;
    font-weight: 700;
    white-space: nowrap;
    

}

.notice-content {
    color:#000000;
    font-size: 18px;
    font-family: Lato, sans-serif;
    text-align: start;
    width: 100%;
} 

.terms-and-conditions {
    color: #147390;
    text-decoration: underline;
    cursor: pointer;
}

.proceed-checkbox {
    text-align: start;
    cursor: pointer;

}

.checkbox-container {
    margin-right: 500px;
    text-wrap: nowrap;
}

.checkbox-label {
    font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/Components/HomeComponents/PrivacyNotice/PrivacyNotice.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,wCAAwC;IACxC,kBAAkB;IAClB,UAAU;IACV,gBAAgB;IAChB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,6BAA6B;IAC7B,YAAY;IACZ,eAAe;IACf,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,mBAAmB;;;AAGvB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,6BAA6B;IAC7B,iBAAiB;IACjB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,eAAe;;AAEnB;;AAEA;IACI,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".notice {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-color: #fff;\n    padding: 15px 35px;\n    border-radius: 10px;\n    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);\n    align-items: start;    \n    width: 80%; \n    max-width: 500px; \n    min-width: 200px; \n    display: flex;\n    flex-direction: column;\n    align-items: flex-start; \n    z-index: 1;\n}\n\n.close-button {\n    position: relative;\n    background-color: transparent;\n    border: none;\n    cursor: pointer;\n    font-size: 20px;\n    align-self: end;\n}\n\n.notice-title {\n    color:#000000;\n    display: flex;\n    align-items: center;\n    font-size: 22px;\n    font-weight: 700;\n    white-space: nowrap;\n    \n\n}\n\n.notice-content {\n    color:#000000;\n    font-size: 18px;\n    font-family: Lato, sans-serif;\n    text-align: start;\n    width: 100%;\n} \n\n.terms-and-conditions {\n    color: #147390;\n    text-decoration: underline;\n    cursor: pointer;\n}\n\n.proceed-checkbox {\n    text-align: start;\n    cursor: pointer;\n\n}\n\n.checkbox-container {\n    margin-right: 500px;\n    text-wrap: nowrap;\n}\n\n.checkbox-label {\n    font-size: 15px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
