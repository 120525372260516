import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PrivacyNotice from '../PrivacyNotice/PrivacyNotice';
import './Questions.css';
import { ChatContext } from '../../../Context/ChatContext';
import { FileContext } from '../../../Context/FileContext';
import useHandleSendMessage from '../../../Hooks/useHandleSendMessage';

function Questions() {

    const [showNotice, setShowNotice] = useState(false);
    const navigate = useNavigate();
    const { allMessages, setAllMessages, messageInput, setMessageInput, error, setError, isLoading, setIsLoading } = useContext(ChatContext);
    const { file, setFile, filePreview, setFilePreview, fileError, setFileError, handleUploadClick, handleRemoveFile, showUploadPrompt, setShowUploadPrompt, handleFileChange}  = useContext(FileContext);
    const [isRecordingMic, setIsRecordingMic] = useState(false);
    const [recognition, setRecognition] = useState(null);

    const { handleSendMessage } = useHandleSendMessage({
        allMessages, setAllMessages,
        messageInput,setMessageInput,
        filePreview, setFilePreview,
        error, setError,
        file, setFile,
        fileError,
        showUploadPrompt, setShowUploadPrompt,
        isLoading, setIsLoading,
      });

    const handleQuestionClick = (e, questionSelected) => {
        e.preventDefault();
        setMessageInput(questionSelected);
        setShowNotice(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setShowNotice(true);
    }

    const handleNoticeClose = () => {
        setShowNotice(false);
    };

    const handleNoticeProceed = () => {
        navigate('/chatbot');
    };


    const handlePlusClick = (e) => {
        e.preventDefault();
    };


    const handleMicClick = (e) => { 
        e.preventDefault();

        if (!isRecordingMic) {
            try {
                const recognition = new window.webkitSpeechRecognition(); 
                recognition.lang = 'en-US'; 
                recognition.start(); 
    
                recognition.onresult = (event) => {
                    const transcript = event.results[0][0].transcript;
                    setMessageInput(transcript); 
                };
    
                recognition.onerror = (event) => {
                    console.error('Speech recognition error:', event.error);
                };

                recognition.onend = () => {
                    setIsRecordingMic(false);
                };

                setRecognition(recognition);
                setIsRecordingMic(true);

            } catch (error) {
                console.error('Error initializing speech recognition:', error);
            }
        } else {
            if (recognition) {
                recognition.stop();
                setIsRecordingMic(false);
            }
        }
    }
    
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage(e);
        }
    }

    return (
        <div className="questions">
            <div className="question-bar-container">
                    <form className="questionbar" onKeyPress={handleKeyPress}>
                        <button className="plus-button">
                                <img src="/plus.png" className="plus-image" onClick={handleUploadClick}/>
                        </button>
                        <input  id="file-input" type="file"  onChange={(e) => handleFileChange(e)}/>

                        {filePreview && 
                            <div className="file-preview-container">
                                    <div className="file-container">
                                        <img src="/file-icon-p.png" className="file-icon"/>
                                        <p className="file-name-w">{filePreview}</p>
                                    </div>
                                        
                                
                                <div className="remove-file">
                                    <button className="remove-button" onClick={handleRemoveFile}>X</button>
                                </div>
                            </div>            
                            }
                        <input
                            className="qinput"
                            placeholder="Share your thoughts..."
                            value={messageInput}
                            onChange={(e) => setMessageInput(e.target.value)}
                
                        />
                        <button className="mic-button">
                            <img src={isRecordingMic ? "/mic-rec.png" : "/microphone.png"} className="mic-image" onClick={handleMicClick}/>
                        </button>

                        <button type="submit" className="submit2-button" onClick={(e)=>handleSubmit(e)}>
                            <img src="/send.png" alt="" className="submit-image" />
                        </button>
                    </form>
                </div>

            <PrivacyNotice showNotice={showNotice} handleClose={handleNoticeClose} handleProceed={handleNoticeProceed} />

                <div className="questions-container">
                        <Link to="/chatbot" className="question-box" onClick={(e)=>handleQuestionClick(e, 'As an individual, what steps can I take to enhance my concentration during my work?')}>
                            <p className='q'>
                                As an individual, what steps can I take to enchance my concentration during my work? 
                            </p>
                            <span className="arrow">➜</span>
                        </Link>
                        <Link to="/chatbot" className="question-box" onClick={(e)=>handleQuestionClick(e, 'As an HR leader, how can I promote a culture that embraces different working styles?')}>
                            <p className='q'>
                            As an HR leader, how can I promote a culture that embraces different working styles?
                            </p>
                            <span className="arrow">➜</span>
                        </Link>
                        <Link to="/chatbot" className="question-box" onClick={(e)=>handleQuestionClick(e, 'As a manager, how can I manage team dynamics to create a supportive and productive workplace?')}>
                            <p className='q'>
                                As a manager, how can I manage team dynamics to create a supportive and productive workplace? 
                            </p>
                            <span className="arrow">➜</span>
                        </Link> 
                </div>
        </div>
    );
}

export default Questions;

