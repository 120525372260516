import'../Login/Login.css';
import NavBar from '../../Components/NavBar/NavBar';
import { useNavigate,useSearchParams } from 'react-router-dom';
import React, { useState} from 'react';
import axios from "axios";

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const userId = searchParams.get('user_id');

    const handleSendResetEmail = async (event) => {
        event.preventDefault(); 

        try {
            const response = await axios.post(`${process.env.REACT_APP_CHATAGENT_URI}/auth/reset-password`, { email });

            if (response.data.success) {
                setSuccess('A reset link has been sent to your email address.');
                setError('');
            } else if (response.data.error){
                setError('Server Error. Please try again.');
                setSuccess('');
            }

        } catch (error) {
            setError('No password is associated with the email. Please log in using LinkedIn or Google.');
            setSuccess('');
        }
    };

    const handleResetPassword = async (event) => {
        event.preventDefault();

        if (password !== confirmPassword) {
            setError('Passwords do not match.');
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_CHATAGENT_URI}/auth/reset-password/${token}/${userId}`, { password });

            if (response.data.success) {
                navigate('/login', { state: { resetSuccess: "Your password has been successfully reset. Please log in." } });
            } else {
                setError(response.data.message);
            }

        } catch (error) {
            setError('Failed to reset password. Please try again.');
        }
    };

    return (
        <div className="login-page">
            <NavBar />
            <main className="login-container">

                <h1 className="login-title">Reset your password</h1>

                {!token && !userId ? (
                <>
                    <h2 className="description">Enter your email to receive a link to reset your password.</h2>
                    <form onSubmit={handleSendResetEmail}>
                        <div className='email-field'>
                            <label htmlFor="email" />
                            <input type="email" 
                            id="email" 
                            required
                            autoComplete="email"
                            className="input-field"
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                            placeholder="Enter Email" />
                        </div>
                        
                        <div className="login-button-container">
                            <button type="submit" className="login-button">SEND</button>
                        </div>
                    </form>

                    {success && <p className="success">{success}</p>}
                    {error && <p className="error">{error}</p>}
                </>
                ) : (
                    <>
           <h2 className="description">Enter your new password.</h2>
                        <form onSubmit={handleResetPassword}>
                            <div className='password-field'>
                                <label htmlFor="password" className="password-label"/>
                                <input 
                                    type="password" 
                                    id="password" 
                                    required
                                    className="input-field"
                                    onChange={(event) => setPassword(event.target.value)}
                                    placeholder="New Password" 
                                />
                            </div>

                            <div className='password-field'>
                                <label htmlFor="password" className="password-label"/>
                                <input 
                                    type="password" 
                                    id="confirmPassword" 
                                    required
                                    className="input-field"
                                    onChange={(event) => setConfirmPassword(event.target.value)}
                                    placeholder="Confirm Password" 
                                />
                            </div>
                            
                            <div className="login-button-container">
                                <button type="submit" className="login-button">RESET PASSWORD</button>
                            </div>
                        </form>
                    </>
                )}
            </main>
        </div>
    );
}

export default ForgotPassword;