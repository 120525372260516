import './Signup.css';
import React from 'react';
import axios from "axios";
import NavBar from '../../Components/NavBar/NavBar';
import { useNavigate } from 'react-router-dom';
import { useState, useContext, useEffect } from 'react';

function Signup() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [industry, setIndustry] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    try {
      const response = await axios.post(`${process.env.REACT_APP_CHATAGENT_URI}/auth/signup`, {firstName, lastName, email, password, role, industry});
      console.log('Response from backend:', response.data);

      if (response.status === 201) {
          // User successfully signed up
          console.log("Signed up");
          navigate('/login');

      } else {
          console.error('Signup failed');
      }
  } catch (error) {
      console.error('Error signing up:', error);
      setError("Email already exists. Please log in or use another email.");
  }

  };

  
  const roles = [
    { id: 1, name: "Executive" },
    { id: 2, name: "Manager" },
    { id: 3, name: "Individual Contributor" },
    { id: 4, name: "Other" }, 
  ];

  const industries = [
    { id: 1, name: "Agriculture and Food Production" },
    { id: 2, name: "Consumer Goods and Retail" },
    { id: 3, name: "Education and Training" },
    { id: 4, name: "Energy and Utilities" },
    { id: 5, name: "Finance, Insurance, and Real-Estate" },
    { id: 6, name: "Healthcare and Pharmaceuticals" },
    { id: 7, name: "Manufacturing and Construction" }, 
    { id: 8, name: "Military and Defense" },
    { id: 9, name: "Technology and Telecommunications"},
  ];
  
    return (
      <div className="signup-page">
      <NavBar/>
      <main className="signup-container">
          <div className="signup-form-wrapper">
            <header className="signup-header">
              <h1 className="signup-title">Create An Account</h1>
             
            </header>
            <form onSubmit={handleSubmit}>

            {/* Row 1 - First / Last name */}
            <div className="signup-form-row">
              <div className="signup-form-col">
                <div className="signup-form-group">
                  <label htmlFor="firstName" className="signup-form-label">
                    First Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    className="signup-form-input"
                    required
                    onChange={(event) => {
                      setFirstName(event.target.value);
                  }}
                  />
                </div>
              </div>
              <div className="signup-form-col">
                <div className="signup-form-group">
                  <label htmlFor="lastName" className="signup-form-label">
                    Last Name <span className="required">*</span>
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    className="signup-form-input"
                    required
                    onChange={(event) => {
                      setLastName(event.target.value);
                  }}
                  />
                </div>
              </div>
            </div>

            {/* Row 2 - Email / Password */}
            <div className="signup-form-row">
              <div className="signup-form-col">
                <div className="signup-form-group">
                  <label htmlFor="email" className="signup-form-label">
                    Email Address <span className="required">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    className="signup-form-input"
                    required
                    autoComplete="email"
                    onChange={(event) => {
                      setEmail(event.target.value);
                  }}
                  />
                </div>
              </div>
              <div className="signup-form-col">
                <div className="signup-form-group">
                  <label htmlFor="password" className="signup-form-label">
                    Password <span className="required">*</span>
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="signup-form-input"
                    required
                    onChange={(event) => {
                      setPassword(event.target.value);
                  }}
                  />
                </div>
              </div>
            </div>

            {/* Row 3 - Role / Industry */}
            <div className="signup-form-row">
              <div className="signup-form-col">
                <div className="signup-form-group">
                  <label htmlFor="role" className="signup-form-label">
                    What is your role? <span className="required">*</span>
                  </label>
                  <div className="signup-form-select">
                    <select 
                    id="role" 
                    className="signup-form-input" 
                    required
                    onChange={(event) => {
                      const selectedRole = roles.find(role => role.id === parseInt(event.target.value));
                      setRole(selectedRole ? selectedRole.name : '');
                    }}

                    >
                      <option value=""></option>
                      {roles.map((role) => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="signup-form-col">
                <div className="signup-form-group">
                  <label htmlFor="industry" className="signup-form-label">
                    What is your industry? <span className="required">*</span>
                  </label>
                  <div className="signup-form-select">
                    <select 
                    id="industry" 
                    className="signup-form-input" 
                    required
                    onChange={(event) => {
                      const selectedIndustry = industries.find(industry => industry.id === parseInt(event.target.value));
                      setIndustry(selectedIndustry ? selectedIndustry.name : '');
                    }}
                    >
                      <option value=""></option>
                      {industries.map((industry) => (
                        <option key={industry.id} value={industry.id}>
                          {industry.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            {error && <p className="error">{error}</p>}
            <div className='submit-button'>
            <button type="submit" className="signup-form-submit">
              SIGN UP
            </button>
            </div>
            </form> 
            
            <div className="signup-form-login">
              <span>Already have an account?</span>{" "}
              <a href="/login" className="signup-form-login-link">
                Log In
              </a>
            </div>
          </div>
        </main>
      </div>
        
  );
}

export default Signup;