import React from 'react';
import NavBar from '../../Components/NavBar/NavBar';
import Hero from '../../Components/HomeComponents/Hero/Hero';
import HomeBody from '../../Components/HomeComponents/HomeBody/HomeBody';

function Home() {
    return (
        <div className ="Home">
            <NavBar />
            <Hero />
            <HomeBody />
        </div>
    );
}

export default Home;
