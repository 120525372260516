import React, { createContext, useState, useEffect } from 'react';

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
    const [allMessages, setAllMessages] = useState([]);
    const [messageInput, setMessageInput] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showFeedback, setShowFeedback] = useState({});
    const [surveyData, setSurveyData] = useState({
            gender: "",
            work_role: "",
            org_type: "",
            age: null,
            education: "",
            years_in_org: null,
            org_industry: "",
            workArrangment: "",
            org_size: "",
            IsJobChange: "",
            XCE_percentile: null,
            XCE1: null,
            XCE2: null,
            XCE3: null,
            survey_id: null
    });

    const resetSurveyData = () => {
        setSurveyData({
            gender: "",
            work_role: "",
            org_type: "",
            age: null,
            education: "",
            years_in_org: null,
            org_industry: "",
            workArrangment: "",
            org_size: "",
            IsJobChange: "",
            XCE_percentile: null,
            XCE1: null,
            XCE2: null,
            XCE3: null,
            survey_id: null
        });
    }; 

    return (
        <ChatContext.Provider value={{ 
            allMessages, setAllMessages, 
            messageInput, setMessageInput,
            isLoading, setIsLoading,
            error, setError,   
            surveyData, setSurveyData,
            resetSurveyData,
            showFeedback, setShowFeedback
            }}>
            {children}
        </ChatContext.Provider>
    );
};
