import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './EndButton.css';
import { ChatContext } from '../../../Context/ChatContext';

const EndButton = () => {
    const navigate = useNavigate();
    const {allMessages} = useContext(ChatContext);

    const handleClick = () => {
        if (allMessages.length >= 2) {
            navigate('/summary');
        }
    };

    return (
        <div className="EndButton">
            <div className="end-container">
                <button className="end-button" onClick={handleClick}>
                    End & Summarize
                </button>
            </div>

        </div>
    );
};

export default EndButton;


