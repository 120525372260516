import React, { useContext } from 'react';
import './NavBar.css'
import { Link } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthContext';

function NavBar() {
    const { isLoggedIn, userLogout } = useContext(AuthContext);

    return (
      <div className="nav-bar">
        <div className="logo-container">
          <Link to="/" className="logo">Mentelia</Link>
        </div>
        
          { isLoggedIn ? (
            <nav className="nav-buttons">
              <div onClick={userLogout} className="log-in">Log out</div>
              <img src="/user-profile-photo.png" className="user-photo" />
            </nav> 
          ):(
            <nav className="nav-buttons">
              <Link to="/login" className="log-in">Log in</Link>
              <Link to="/signup" className="sign-up">Sign up</Link> 
            </nav>
          )}
      

      </div>
  
  
    );
  }
  
  export default NavBar;