import React from 'react';
import './PrivacyNotice.css';

function PrivacyNotice({ showNotice, handleClose, handleProceed }) {
    return (
        <>
            {showNotice && (
                <div className="notice">
                    <button className="close-button" onClick={handleClose}>x</button>
                    <div className="notice-content">
                            <h1 className="notice-title">
                                <strong>Privacy Notice</strong>
                            </h1>
                            <p className="notice-content">
                                Any information you provide on this website, including text, queries, or personal details,
                                will be treated with the utmost confidentiality. We prioritize the security and privacy of 
                                your data. By checking the box below, you agree to the <span className="terms-and-conditions">Terms and Conditions</span>.
                            </p>

                         <div className='checkbox-container'>
                            <input type="checkbox" className="proceed-checkbox" onChange={handleProceed} />
                            <span className="checkbox-label"> I agree</span> <span className="required">*</span>
                        </div>   

                    </div>
                </div>
            )}
        </>
    );
}

export default PrivacyNotice;
