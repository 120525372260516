import React, { useState, useContext } from 'react';
import './Tokens.css';
import { TokensContext } from '../../../Context/TokensContext';

const Tokens = () => {
    const [showPopUp, setShowPopUp] = useState(false);
    const { tokens } = useContext(TokensContext);

    const handleInfoHover = () => {
        setShowPopUp(true);
    };

    const handleInfoLeave = () => {
        setShowPopUp(false);
    };

    return (
        <div className="tokens">
            <button className="tokens-button" onMouseEnter={handleInfoHover} onMouseLeave={handleInfoLeave}>
                <img src='/credits-info.png' className="tokens-image" alt="tokens Info" />
            </button>
            <div className="tokens-text">
                Tokens available: {tokens}/10
            </div>
            {showPopUp &&
                <div className="tokens-popup">
                    <p>Ask questions thoughtfully; tokens are limited.</p>
                    <p>Share with a friend for more tokens and wider benefits!</p>
                </div>
            }

        </div>
    );
};

export default Tokens;
