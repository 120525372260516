// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disclaimer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.disclaimer-banner {
    margin: auto; 
    text-align: center;
}

.disclaimer-text {
    color: #fff;
    font-style: italic;
    font-size: 18px;
    margin-bottom: 150px;
}




`, "",{"version":3,"sources":["webpack://./src/Components/HomeComponents/Disclaimer/Disclaimer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;;AAE3B;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,eAAe;IACf,oBAAoB;AACxB","sourcesContent":[".disclaimer-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    \n}\n\n.disclaimer-banner {\n    margin: auto; \n    text-align: center;\n}\n\n.disclaimer-text {\n    color: #fff;\n    font-style: italic;\n    font-size: 18px;\n    margin-bottom: 150px;\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
