import React from "react";
import './Features.css';

function Features() {
  const Features = [
    {
      title: "We Prioritize Your Privacy First",
      description:
        "Respecting your privacy is fundamental to us. All conversations and questions are kept confidential, ensuring you receive the utmost quality and feedback.",
      image: "https://cdn.builder.io/api/v1/image/assets/TEMP/adabb108bf5df3fb1314ed28e8936d72c85c4a431ca7825f4eb44897acfcfb9b?apiKey=633b5254d36f4c2a8892fa81b77d5cd0&",
      imageAlt: "Illustration representing privacy and confidentiality",
      reversed: false,
    },
    {
      title: "A Unique Opportunity",
      description:
        "Neurodiverse Aware AI offers a distinct, tailored chat experience ensuring a comfortable space for asking questions that might feel intimidating.",
      image: "https://cdn.builder.io/api/v1/image/assets/TEMP/b2b74f020a0fcee17df236b1a3179d7623733fb74da9ae66d3bded50a34c494c?apiKey=633b5254d36f4c2a8892fa81b77d5cd0&",
      imageAlt: "Illustration representing a unique opportunity",
      reversed: true,
    },
    {
      title: "Beyond A Chat",
      description:
        "Neurodiverse Aware AI goes above and beyond question-and-feedback. Equipped with an insights summary, your conversations are elevated to a whole new level of depth and understanding.",
      image: "https://cdn.builder.io/api/v1/image/assets/TEMP/1be92fdef92f320bbad6320a80f8c1c0d245b4c6be818ab627c8a1a030e400fc?apiKey=633b5254d36f4c2a8892fa81b77d5cd0&",
      imageAlt: "Illustration representing going beyond a chat",
      reversed: false,
    },
  ];

  return (
    <div className="features">
      <section className="features-section">
        <h2 className="features-title">How We Can Help</h2>
        {Features.map((section, index) => (
          <FeatureItem key={index} {...section} />
        ))}
      </section>
    </div>
  );
}

function FeatureItem({
  title,
  description,
  image,
  imageAlt,
  reversed,
}) {
  return (
    <article className={`feature-item ${reversed ? "reversed" : ""}`}>
      <div className="feature-content">
        <h3 className="feature-title">{title}</h3>
        <p className="feature-description">{description}</p>
      </div>
      <div className="feature-image-wrapper">
        <img src={image} alt={imageAlt} className="feature-image" />
      </div>
    </article>
  );
}

export default Features;
